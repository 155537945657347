<template>
  <div class="pageview">
    <div>
      <!-- <van-sticky>
      </van-sticky> -->

      <!-- <div class="topBox">
        <div @click="show = true">{{ OrganName }}</div>
        <div style="margin-left:10px;">
          <van-icon name="arrow-down" />
          <img src="../../assets/箭头.png"
            alt="">
        </div>
        <van-popup v-model="show"
          round
          style="color:black;"
          position="bottom">
          <van-cascader v-model="cascaderValue"
            title="请选择所在地区"
            :options="orgonList"
            @close="show = false"
            :field-names="{
              text: 'OName',
              value: 'OCode',
              children: 'Children',
            }"
            @finish="onFinish" />
        </van-popup>
      </div> -->
    </div>
    <!-- <van-nav-bar title="便民服务" fixed /> -->
    <div class="bgcolor"></div>
    <!-- banner切换 -->
    <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="banner in bannerImgs" :key="banner"
        ><img :src="banner" style="height: 172px" />
      </van-swipe-item>
    </van-swipe>
    <div style="margin: 10px auto; border-radius: 5px; width: 95%">
      <div style="border-radius: 5px; background: #ffff">
        <!-- <h3 class="grouptitle">便民服务</h3> -->
        <van-grid class="wktype" :border="false" :square="true" :column-num="5">
          <template>
            <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar')">
              <van-image
                class="iconimg"
                :src="require('../../assets/五星党建.png')"
              ></van-image>
              <span class="wktext">五星党建</span>
            </van-grid-item>
          </template>
          <van-grid-item icon="photo-o" @click="gotoUrl('/work')">
            <van-image
              class="iconimg"
              :src="require('../../assets/网上办事.png')"
            ></van-image>
            <span class="wktext">网上办事</span>
          </van-grid-item>
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrl('/activity')">
            <van-image class="iconimg"
              :src="require('../../assets/社区活动.png')"></van-image>
            <span class="wktext">社区活动</span>
          </van-grid-item> -->
          <van-grid-item icon="photo-o" @click="gotoUrl('/article')">
            <van-image
              class="iconimg"
              :src="require('../../assets/zixun.png')"
            ></van-image>
            <span class="wktext">社区资讯</span>
          </van-grid-item>
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrl('/volunteerAct')">
            <van-image class="iconimg"
              :src="require('../../assets/志愿者活动.png')"></van-image>
            <span class="wktext">志愿者活动</span>
          </van-grid-item> -->
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrl('/partymember')">
            <van-image class="iconimg"
              :src="require('../../assets/智慧党建.png')"></van-image>
            <span class="wktext">智慧党建</span>
          </van-grid-item> -->
          <!-- <template v-else>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/scenery')">
          <van-image class="iconimg"
            :src="require('../../assets/shequfengcai.png')"></van-image>
          <span class="wktext">社区风采</span>
        </van-grid-item>
      </template> -->
          <!-- <template>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/village')">
          <van-image class="iconimg"
            :src="require('../../assets/shijianyinhang.png')"></van-image>
          <span class="wktext">我的社区(村)</span>
        </van-grid-item>
      </template> -->
          <template>
            <van-grid-item icon="photo-o" @click="gotoUrl('/ylOrder')">
              <van-image
                class="iconimg"
                :src="require('../../assets/医康养护.png')"
              ></van-image>
              <span class="wktext">医康养护</span>
            </van-grid-item>
          </template>
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrl('/list')">
            <van-image class="iconimg"
              :src="require('../../assets/jiuye.png')"></van-image>
            <span class="wktext">求职招聘</span>
          </van-grid-item> -->

          <!-- <template>
            <van-grid-item icon="photo-o"
              @click="gotoUrl('/register')">
              <van-image class="iconimg"
                :src="require('../../assets/yiqing.png')"></van-image>
              <span class="wktext">疫情防控</span>
            </van-grid-item>
          </template> -->

          <!-- <template>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/notice')">
          <van-image class="iconimg"
            :src="require('../../assets/qiuzhizhaopin.png')"></van-image>
          <span class="wktext">通知公告</span>
        </van-grid-item>
      </template> -->
          <!-- <template>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/list')">
          <van-image class="iconimg"
            :src="require('../../assets/qiuzhizhaopin.png')"></van-image>
          <span class="wktext">求职招聘</span>
        </van-grid-item>
      </template> -->
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrl('/shop')">
            <van-image class="iconimg"
              :src="require('../../assets/便民网点.png')"></van-image>
            <span class="wktext">惠民活动</span>
          </van-grid-item> -->

          <van-grid-item icon="photo-o" @click="gotoUrl('/neighbor')">
            <van-image
              class="iconimg"
              :src="require('../../assets/便民网点.png')"
            ></van-image>
            <span class="wktext">邻里中心</span>
          </van-grid-item>
        </van-grid>
      </div>
    </div>

    <!-- <div>
      <router-link to="/register">
        <img style="width:90%;margin:8px 5%"
          src="../../assets/dengji.png"
          alt="">
      </router-link>
    </div> -->
    <!-- <br class="brakline" /> -->
    <div style="width: 95%; margin: 15px auto" @click="gotoUrl1">
      <div class="essay" style="margin: 0; width: 100%; padding: 0">
        <div
          class="essayContent"
          style="
            padding: 0;
            width: 100%;
            box-shadow: 0px 0px 4px #e7e1e1;
            border-radius: 11px;
          "
          v-html="appInfo.WorkTime"
        ></div>
      </div>
    </div>
    <!-- <div style="border-left: 7px solid #eee;border-right: 7px solid #eee;border-bottom: 7px solid #eee;border-top: 1px solid #eee;">
      <h3 class="grouptitle">工作时间</h3>
      <div class="information">
        <div class="center"
          style="border:0px;line-height: 10px;padding: 4px 15px;">
          <div v-html="appInfo.WorkTime"></div>
        </div>
      </div>
    </div> -->
    <!-- 五星支部创建 -->
    <!-- <div style="background:#fff;margin:0 auto 10px;width:95%;border-radius:5px;">
      <div style="padding-top:15px;display: flex;align-items: center;">
        <img src="../../assets/五星.png"
          alt=""
          width="16px">
        <div class="grouptitle">五星支部创建</div>
      </div>
      <div class="information">
        <div class="center"
          v-for="item in partyNoticeList"
          :key="item.index">
          <div @click="gotoUrl('/fivestar/wuXingXq/' + item.AId)">
            <div class="Content"
              style="border-bottom: 1px solid rgb(245 243 243);">
              <div class="ContentB">
                <div class="centerTitle van-ellipsis"
                  style="font-size:16px">{{ item.Title }}</div>
                <span class="centertime van-multi-ellipsis--l2"
                  style="margin:5px 0px;font-size: 13px;color: #666666;">{{ item.Outline }}</span>
                <span class="centertime"
                  style="color: #999999;">{{ item.StartTime | timeFilter}} </span>
              </div>
              <div style="display: inline-block;width: 33%;"
                class="ContentA"
                ref="getHeight">
                <img v-if="item.AImgUrl"
                  :src="item.AImgUrl"
                  alt="" />
              </div>
            </div>
          </div>
        </div>
        <van-empty description="暂无信息"
          v-if="!partyNoticeList.length" />
      </div>
    </div> -->
    <div
      style="
        background: #fff;
        margin: 0 auto 10px;
        width: 95%;
        border-radius: 5px;
      "
    >
      <div style="padding-top: 15px; display: flex; align-items: center">
        <img src="../../assets/五星.png" alt="" width="16px" />
        <div class="grouptitle">便民资讯</div>
      </div>
      <div class="information">
        <!-- 全部 -->
        <van-list
          v-model="loading"
          :finished="finished"
          :offset="100"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div class="center" v-for="item in commNoticeList" :key="item.index">
            <div @click="gotoUrl('/article/detail/' + item.RIId)">
              <div
                class="Content"
                style="
                  border-bottom: 1px solid rgb(245 243 243);
                  position: relative;
                "
              >
                <div class="ContentB">
                  <div class="centerTitle van-ellipsis" style="font-size: 16px">
                    {{ item.Title }}
                  </div>
                  <div
                    class="centertime van-multi-ellipsis--l2"
                    style="margin: 5px 0px; font-size: 13px; color: #666666"
                  >
                    {{ item.Summary }}
                  </div>
                  <div
                    class="centertime"
                    style="
                      color: #999999;
                      bottom: 8px;
                      position: absolute;
                      display: flex;
                      align-items: center;
                      width: 58%;
                      justify-content: space-between;
                    "
                  >
                    <div>{{ item.IssTime | timeFilter }}</div>
                    <div>阅读 {{ item.ReadNum }}</div>
                  </div>
                </div>
                <div
                  style="display: inline-block; width: 33%"
                  class="ContentA"
                  ref="getHeight"
                >
                  <img
                    v-if="item.Thematic"
                    :src="item.Thematic"
                    alt=""
                    style="min-height: 80px"
                  />
                  <img
                    v-else
                    src="http://alycommunity.anluoyun.cn/sx-jgz/2023/6/d230d06c-3c3.png"
                    alt=""
                    style="min-height: 80px"
                  />
                </div>
              </div>
            </div>
          </div>
          <van-button v-if="showBtn" @click="backTop" class="backTop"
            ><img
              src="https://dximg.dexian.ren/lczhsq/top.png"
              style="width: 13px; vertical-align: middle"
              alt=""
          /></van-button>
          <!-- <van-empty description="暂无信息" v-if="!commNoticeList.length" /> -->
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/index";
import {
  WeGetRealInfoPage,
  WePBGetRealInfoPage,
  WeGetMyActivityPage,
} from "@/api/RealInfo";
import { WeGetAppletInfo } from "@/api/index";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetOrganTree } from "@/api/Organ";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data() {
    return {
      showBtn: false,
      loading: false,
      finished: false,
      appInfo: {},
      bannerImgs: [],
      active: 0,
      partyNoticeList: [],
      commNoticeList: [],
      area: "",
      show: false,
      cascaderValue: "",
      orgonList: [],
      OrganName: "",
      oCode: "",
      OrgCode: "",
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
    };
  },
  filters: {
    timeFilter(value) {
      if (value != undefined) {
        // let gstime = value.replace('T', ' ')
        let gstime = value.substring(0, 10);
        return gstime;
      }
    },
  },
  created() {
    this.getOrgonTree();
    this.getAppInfo();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.area = config.area;
    // 活动五星党建亮点
    // this.getNoticeList('书记茶话会');
    // this.getNoticeList('多元化社区');
    // this.getNoticeList('社群e家联防巡逻');
    // this.getNoticeList('爱心大妈');
    // this.getNoticeList('餐饮专题培训');
    // 获取活动通知
    this.getCommNoticeList();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.showBtnF, true);
  },
  methods: {
    handleScroll() {
      const scrollTop = this.getScroll().top;
      this.scrollTop = scrollTop;
    },
    //获得页面向左、向上卷动的距离
    getScroll() {
      return {
        top:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0,
      };
    },
    backTop() {
      // 开个定时器返回顶部时缓慢优雅一些，是 document.body.scrollTop = 0 的升级版
      const timer = setInterval(() => {
        // 页面顶部卷去高度
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        console.log(scrollTop, "scrollTop");
        // 高度控速 = 页面顶部卷去高度正值 / n ===> n 越大，返回越慢
        let ispeed = Math.floor(-scrollTop / 5);
        console.log(scrollTop, "scrollTop");
        console.log(ispeed, "ispeed");
        // 页面顶部卷去高度 + 高度控速 ===> 和不为零，定时器一直开启（不明白的同志可以尝试同时打印 scrollTop 和 ispeed）
        document.body.scrollTop = scrollTop + ispeed;
        document.documentElement.scrollTop = scrollTop + ispeed;
        // 页面顶部卷去高度 + 高度控速 ===> 和为零返回顶部，清除定时器
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    showBtnF() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    // 组织机构
    getOrgonTree() {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
        this.OrganName = res.data.data[0].OName;
        // this.oCode = res.data.data[0].OName;
        this.OrgCode = res.data.data[0].Children[0].OCode;
        let Code = this.getAllIds(this.orgonList);
        Code.forEach((i) => {
          if (this.cascaderValue == i.OCode) {
            this.OrganName = i.OName;
          }
        });
      });
    },
    // 循环遍历树形结果
    getAllIds(tree) {
      let result = [];
      if (!Array.isArray(tree)) {
        return result;
      }
      tree.forEach((node) => {
        result.push({ OCode: node.OCode, OName: node.OName });
        if (Array.isArray(node.Children)) {
          // result.push(...getAllIds(node.children));
          result = result.concat(this.getAllIds(node.Children));
        }
      });
      return result;
    },
    // 社区搜索
    onFinish: function (val) {
      this.show = false;
      console.log(val);
      // this.fieldValue = val.selectedOptions
      //   .map((option) => option.OName)
      //   .join("/");
      this.fieldValue = val.selectedOptions[val.tabIndex].OName;
      this.OrganName = this.fieldValue;
      this.OCode = val.value;
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    gotoUrl1() {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../JobRegister/index",
        });
      } else {
        // this.$router.push(path);
        alert("请在小程序中打开");
      }
    },
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 下拉刷新
    onLoad() {
      this.loading = true;
      this.listfrom.page = this.listfrom.page + 1;
      this.getCommNoticeList();
    },
    // 获取活动通知
    getCommNoticeList() {
      WePBGetRealInfoPage(this.listfrom).then((res) => {
        this.loading = false;

        if (res.data.code == 0) {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束

          if (rows == null || rows.length === 0) {
            // 加载结束
            console.log("结束");
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.commNoticeList = this.commNoticeList.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.commNoticeList.length >= res.data.count) {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
      });
    },
    // 通知消息 五星支部创建
    getNoticeList(row) {
      WeGetMyActivityPage({
        taskClassification: row,
        page: 1,
        limit: 1,
        openid: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          this.partyNoticeList.push(res.data.data[0]);
          for (var i = 0; i < this.NoticeList.length; i++) {
            var item = this.NoticeList[i].IssTime;
            item = this.dataFormat(item);
            this.partyNoticeList[i].IssTime = item;
          }
        }
      });
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1;
          imgs[1] = list.data.SysPic2;
          imgs[2] = list.data.SysPic3;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.topBox {
  background-color: #f63e3b;
  padding: 10px 10px 0 10px;
  color: white;
  display: flex;
  line-height: 17px;
}
.ContentB {
  display: inline-block;
  width: 59% !important;
  margin-right: 6%;
  margin-left: 0% !important;
}

.bgcolor {
  position: absolute;
  background: linear-gradient(#f63e3b, #fff);
  /* background: #f63e3b; */
  height: 150px;
  width: 100%;
}

.pageview {
  min-height: 100%;
  background: #fff;
}

.homebanner {
  width: 95%;
  margin: auto;
  margin-top: 5px;
}

.homebanner img {
  width: 100%;
  vertical-align: top;
  border-radius: 5px;
}

.wktime {
  padding: 0 20px;
}

.wktype {
  padding: 0 5px 5px;
}

.orginview .iconimg {
  width: 62%;
}

.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}

.wktype .iconimg {
  width: 80%;
}

.wktype .wktext {
  margin-top: 8px;
  font-size: 0.75rem;
}

.grouptitle {
  text-indent: 5px;
  font-size: 18px;
  color: #231815;
  font-weight: 700;
}

.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}

.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fuwenben p {
  margin: 0 !important;
}
.backTop {
  position: fixed;
  bottom: 40px;
  right: 17px;
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.68);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
}
</style>
